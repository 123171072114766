<script lang="ts" setup>
	import { defineAsyncComponent, ref, computed, onMounted } from 'vue';

	import type { syncActionNoId, syncActionRule, syncActionAction } from '../parsers/sync-actions';

	import CfxButton from '../components/button.vue';


	const ruleTypeComponents = {
		// date: defineAsyncComponent(() => import('./department-rule-types/date.vue')),
		duration: defineAsyncComponent(() => import('./department-rule-types/duration.vue')),
	};
	const actionTypeComponents = {
		team: defineAsyncComponent(() => import('./sync-action-actions/team.vue')),
		department: defineAsyncComponent(() => import('./sync-action-actions/department.vue')),
	};

	const props = defineProps<{
		modelValue:	syncActionNoId;
	}>();

	const emit = defineEmits<{
		(e: 'update:modelValue', modelValue: syncActionNoId): void;
		(e: 'delete'): void;
		(e: 'duplicate'): void;
	}>();

	const value = computed({
		get: () => props.modelValue,
		set: modelValue => emit('update:modelValue', modelValue),
	});


	function onClickCreateRule() {
		value.value.rules = [
			...value.value.rules,
			{
				'target':	'',
				'type':		'',
				'data':		{},
			},
		];
	}
	function deleteRule(index: number) {
		value.value.rules.splice(index, 1);
		value.value.rules = [ ...value.value.rules ];
	}
	function onClickCreateAction() {
		value.value.actions = [
			...value.value.actions,
			{
				data: {},
				type: '',
			},
		];
	}
	function deleteAction(index: number) {
		value.value.actions.splice(index, 1);
		value.value.actions = [ ...value.value.actions ];
	}


	const currentTab	= ref<'rules' | 'actions'>('rules');
	const currentRule	= ref<syncActionRule|undefined>();
	const currentAction	= ref<syncActionAction|undefined>();
</script>

<template lang="pug">
.flex.flex-col
	.flex.items-center.pb-6(class="border-b-2 dark:border-slate-700")
		.mr-2 Aktiviert:
		.flex-none.tooltip(:data-tip="value.enabled ? 'Deaktivieren' : 'Aktivieren'")
			input.toggle.toggle-primary.block(
				type="checkbox"
				:checked="value.enabled"
				@click="value.enabled = !value.enabled"
			)

		.flex-1

		.flex-none: .tabs
			.tab.tab-bordered(
				:class="{ 'tab-active': currentTab == 'rules'}"
				@click="currentTab = 'rules'"
			) Regeln
			.tab.tab-bordered(
				:class="{ 'tab-active': currentTab == 'actions'}"
				@click="currentTab = 'actions'"
			) Aktionen

		.flex-1

		.flex.gap-2
			cfx-button.cfx-button-clear.cfx-button-icon.cfx-button-small(@click="emit('duplicate')")
				//- Duplizieren
				svg.w-6.h-6(xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor")
					path(stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75")
			cfx-button.cfx-button-clear.cfx-button-icon.cfx-button-small.cfx-button-danger(@click="emit('delete')")
				//- Löschen
				svg.w-6.h-6(xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor")
					path(stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0")

	.flex(v-if="currentTab == 'rules'")
		.flex-none.flex.flex-col.gap-4.pt-6(style="width:300px; min-height:300px")
			.text-center Wenn folgende Regeln zutreffen...

			ul.flex-1.overflow-y-scroll
				li.py-1.px-2.flex.rounded.flex.items-center.cursor-pointer(
					v-for="rule, i of value.rules"
					@click="currentRule = value.rules[i]"
					class="hover:bg-slate-200/50 dark:hover:bg-slate-700/50"
					:class="currentRule == value.rules[i] && 'bg-slate-200 dark:bg-slate-700 hover:bg-slate-200 dark:hover:bg-slate-700'"
				)
					.flex-1 {{ rule.type || 'Unkonfigurierte Regel' }}
					button.flex-none(
						v-if="currentRule == value.rules[i]"
						@click="deleteRule(i)"
					)
						svg.w-6.h-6(xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor")
							path(stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0")


			cfx-button(@click="onClickCreateRule") Neue Regel anlegen

		.divider.divider-horizontal

		.flex-1.flex.flex-col.pt-6.rounded-lg(
			v-if="currentRule"
			class="bg-slate-100 dark:bg-slate-800"
		)
			.flex.gap-6.mb-6
				select.select.select-sm.select-bordered.w-full.flex-1(v-model="currentRule.target")
					option(value="einstellungsdatum") Einstellungsdatum

				select.select.select-sm.select-bordered.w-full.flex-1(v-model="currentRule.type")
					option(value="duration") Relativer Zeitpunkt

			component(
				v-if="currentRule && ruleTypeComponents[currentRule.type]"
				:is="ruleTypeComponents[currentRule.type]"
				v-model="currentRule.data"
			)

	.flex(v-if="currentTab == 'actions'")
		.flex-none.flex.flex-col.gap-4.pt-6(style="width:300px; min-height:300px")
			.text-center ...folgende Aktionen durchführen

			ul.flex-1.overflow-y-scroll
				li.py-1.px-2.flex.rounded.flex.items-center.cursor-pointer(
					v-for="action, i of value.actions"
					@click="currentAction = value.actions[i]"
					class="hover:bg-slate-200/50 dark:hover:bg-slate-700/50"
					:class="currentAction == value.actions[i] && 'bg-slate-200 dark:bg-slate-700 hover:bg-slate-200 dark:hover:bg-slate-700'"
				)
					.flex-1 {{ action.type || 'Unkonfigurierte Aktion' }}
					button.flex-none(
						v-if="currentAction == value.actions[i]"
						@click="deleteAction(i)"
					)
						svg.w-6.h-6(xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor")
							path(stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0")


			cfx-button(@click="onClickCreateAction") Neue Aktion anlegen

		.divider.divider-horizontal

		.flex-1.flex.flex-col.pt-6.rounded-lg(
			v-if="currentAction"
			class="bg-slate-100 dark:bg-slate-800"
		)
			.flex.gap-6.mb-6
				select.select.select-sm.select-bordered.w-full.flex-1(v-model="currentAction.type")
					option(value="team") Team
					option(value="department") Department

			component(
				v-if="currentAction && actionTypeComponents[currentAction.type]"
				:is="actionTypeComponents[currentAction.type]"
				v-model="currentAction.data"
			)
</template>
