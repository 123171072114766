<script setup lang="ts">
	import { ref, watch } from 'vue';

	import CfxTitle from '../components/title.vue';
	import CfxInput from '../components/input.vue';
	import CfxButton from '../components/button.vue';
	import CfxFormField from '../components/form-field.vue';
	import CfxCheckbox from '../components/checkbox.vue';

	import { httpPostRequest, httpRequest } from '../http-request';
	import { apicredentials } from '@prisma/client';


	interface IPlandayInstance {
		id?:			string;
		name:			string;
		enabled:		boolean,
		api_url:		string;
		app_id:			string;
		refresh_token:	string;
		delete:			boolean;
		hasAccessToken:	boolean;
	};
	interface IEloomiInstance {
		id?:			string;
		name:			string;
		enabled:		boolean,
		api_url:		string;
		client_id:		string;
		client_secret:	string;
		delete:			boolean;
		hasAccessToken:	boolean;
	}

	type apiCredentialsResponseItem = apicredentials & {
		hasAccessToken: boolean;
	};


	const planday	= ref<IPlandayInstance[]>([]);
	const eloomi	= ref<IEloomiInstance[]>([]);


	const isLoading		= ref(false);
	const isError		= ref(false);
	const fetchApiCredentials = async () => {
		isLoading.value	= true;
		isError.value	= false;
		try {
			const plandayInstances	= [] as IPlandayInstance[];
			const eloomiInstances	= [] as IEloomiInstance[];
			const response			= await httpRequest('/api/apicredentials') as apiCredentialsResponseItem[];

			for (const c of response) {
				if (c.platform == 'planday') {
					plandayInstances.push({
						id:				c.id,
						name:			c.name,
						enabled:		c.enabled,
						hasAccessToken:	c.hasAccessToken,
						delete:			false,
						...c.credentials as any,
					});
				}
				else if (c.platform == 'eloomi') {
					eloomiInstances.push({
						id:				c.id,
						name:			c.name,
						enabled:		c.enabled,
						hasAccessToken:	c.hasAccessToken,
						delete:			false,
						...c.credentials as any,
					});
				}
			}

			planday.value	= plandayInstances;
			eloomi.value	= eloomiInstances;
			isError.value	= false;
		}
		catch (err) {
			isError.value = true;
		}
		isLoading.value = false;
	};
	fetchApiCredentials();




	const refreshAccessToken = async (instance: IPlandayInstance | IEloomiInstance) => {
		const response = await httpPostRequest(`/api/apicredentials/refresh-token/${instance.id}`);
		if (response.accessToken) instance.hasAccessToken = true;
	};

	const addEloomi = async () => {
		eloomi.value.push({
			name:			'',
			enabled:		false,
			api_url:		'https://api.eloomi.com',
			client_id:		'',
			client_secret:	'',
			delete:			false,
			hasAccessToken:	false,
		});
	};
	const addPlanday = async () => {
		planday.value.push({
			name:			'',
			enabled:		false,
			api_url:		'https://id.planday.com',
			app_id:			'',
			refresh_token:	'',
			delete:			false,
			hasAccessToken:	false,
		});
	};

	const deleteEloomi = async (instance: IEloomiInstance) => {
		const index = eloomi.value.indexOf(instance);
		eloomi.value.splice(index, 1);
	}
	const onSubmit = async () => {
		const response = await httpPostRequest(
			'/api/apicredentials/update',
			[
				...planday.value.map(p => ({
					id:				p.id,
					enabled:		p.enabled,
					platform:		'planday',
					name:			p.name,
					delete:			p.delete,
					hasAccessToken:	p.hasAccessToken,
					credentials: {
						api_url:		p.api_url,
						app_id:			p.app_id,
						refresh_token:	p.refresh_token,
					},
				})),
				...eloomi.value.map(e => ({
					id:				e.id,
					enabled:		e.enabled,
					platform:		'eloomi',
					name:			e.name,
					delete:			e.delete,
					hasAccessToken:	e.hasAccessToken,
					credentials: {
						api_url:		e.api_url,
						client_id:		e.client_id,
						client_secret:	e.client_secret,
					},
				})),
			]
		);

		fetchApiCredentials();
	};
</script>

<template lang="pug">
cfx-title.mb-12 API Zugangsdaten

cfx-title(class="text-center p-12 text-slate-200 dark:text-slate-700" v-if="isLoading") Loading...
cfx-title(class="text-center p-12 text-slate-200 dark:text-slate-700" v-if="isError") Einstellungen konnte nicht geladen werden

form(
	v-if="!isError && !isLoading"
	@submit.prevent="onSubmit"
	class="[&>*:not(:first-child)]:mt-12 [&>*:not(:first-child)]:block"
)
	section
		cfx-title.mb-4 Planday

		div(
			v-for="instance in planday"
			class="card bg-slate-500/10 px-6 py-6 mt-4"
		)
			.flex.items-start.mb-6
				.flex-none.tooltip.tooltip-right(:data-tip="instance.enabled ? 'Deaktivieren' : 'Aktivieren'")
					input.toggle.toggle-primary.block(type="checkbox" v-model="instance.enabled")
				.flex-1
				.flex-none
					.badge.cursor-pointer.bg-green-700.text-green-300(
						v-if="instance.hasAccessToken"
						@click="refreshAccessToken(instance)"
					): small Access-Token verfügbar
					div(
						class=`
							badge cursor-pointer
							bg-slate-300 dark:bg-slate-700
							text-slate-500
						`
						v-if="!instance.hasAccessToken"
						@click="refreshAccessToken(instance)"
					): small Noch kein Access-Token

			div(class="[&>*:not(:first-child)]:mt-4 mb-8")
				cfx-form-field(label="Name"): cfx-input.w-full(
					v-model="instance.name"
					placeholder="z.B.: Planday"
				)
				cfx-form-field(label="Token Endpoint-URL"): cfx-input.w-full(
					v-model="instance.api_url"
					placeholder="z.B.: https://id.planday.com"
				)
				cfx-form-field(label="App ID"): cfx-input.w-full(
					v-model="instance.app_id"
					placeholder="z.B.: 49b8fea8-bdea-4712-a66b-666c350116d1"
				)
				cfx-form-field(label="Refresh Token"): cfx-input.w-full(
					v-model="instance.refresh_token"
					placeholder="z.B.: kgR38VHqWEKBUkSiJKUWYQ"
				)
			.flex
				.flex-1
				.flex-none
					cfx-button.cfx-button-small.cfx-button-danger(@click="deleteEloomi(instance)") Löschen

		.text-center.mt-6
			cfx-button(@click="addPlanday") Planday Instanz hinzufügen

	section
		cfx-title Eloomi

		div(
			v-for="instance in eloomi"
			class="card bg-slate-500/10 px-6 py-6 mt-4"
		)
			.flex.items-start.mb-6
				.flex-none.tooltip.tooltip-right(:data-tip="instance.enabled ? 'Deaktivieren' : 'Aktivieren'")
					input.toggle.toggle-primary.block(type="checkbox" v-model="instance.enabled")
				.flex-1
				.flex-none
					.badge.cursor-pointer.bg-green-700.text-green-300(
						v-if="instance.hasAccessToken"
						@click="refreshAccessToken(instance)"
					): small Access-Token verfügbar
					div(
						class=`
							badge cursor-pointer
							bg-slate-300 dark:bg-slate-700
							text-slate-500
						`
						v-if="!instance.hasAccessToken"
						@click="refreshAccessToken(instance)"
					): small Noch kein Access-Token

			div(class="[&>*:not(:first-child)]:mt-4 mb-8")
				cfx-form-field(label="Name"): cfx-input.w-full(
					placeholder="z.B.: Eloomi Standort Oldenburg"
					v-model="instance.name"
				)
				cfx-form-field(label="API URL"): cfx-input.w-full(
					placeholder="z.B.: https://api.eloomi.com"
					v-model="instance.api_url"
				)
				cfx-form-field(label="Client ID"): cfx-input.w-full(
					placeholder="z.B.: 415"
					v-model="instance.client_id"
				)
				cfx-form-field(label="Client Secret"): cfx-input.w-full(
					placeholder="z.B.: kgR38VHqWEKBUkSiJKUWYQ"
					v-model="instance.client_secret"
				)
			.flex
				.flex-1
				.flex-none
					cfx-button.cfx-button-small.cfx-button-danger(@click="deleteEloomi(instance)") Löschen

		.text-center.mt-6
			cfx-button(@click="addEloomi") Eloomi Instanz hinzufügen

	.text-center
		cfx-button(type="submit") Speichern
</template>
