<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { httpPostRequest, httpRequest } from '../http-request';
import { z } from 'zod';
import type { apicredentials, platformAccounts } from '@prisma/client';

import CfxTitle from '../components/title.vue';

import { ExclamationIcon } from '@heroicons/vue/outline';


type apiCredentialsResponseItem = apicredentials & {
	hasAccessToken: boolean;
};

type platformAccount = platformAccounts & {
	userIds:	Record<string, string>;
};

const dateSchema = z.preprocess((arg) => {
	if (typeof arg == "string" || arg instanceof Date) {
		return new Date(arg);
	}
}, z.date());



const currentTab			= ref('status');
const isStatusVisible		= computed(() => currentTab.value == 'status');
const isHistoryVisible		= computed(() => currentTab.value == 'history');
const isBlacklistVisible	= computed(() => currentTab.value == 'blacklist');


const {
	data: instances
} = useQuery({
	queryKey: [ 'platformInstances' ],
	queryFn: () => {
		return httpRequest('/api/apicredentials') as Promise<apiCredentialsResponseItem[]>;
	},
});


const {
	isLoading:	isStatusLoading,
	isError:	isStatusError,
	data:		accounts,
} = useQuery({
	queryKey: [ 'sync-status' ],
	queryFn: () => {
		return httpRequest('/api/apicredentials/sync-status') as Promise<platformAccount[]>
	},
	enabled: isStatusVisible,
});


const getInstancesByPlatform = (
	account:	platformAccount,
	platform?:	string
): apiCredentialsResponseItem[] => {
	let i = Object.keys(account.userIds)
		.map(id => {
			return instances.value?.find(instance => instance.id == id);
		})
		.filter(a => a);

	if (platform) {
		i = i.filter(instance => instance?.platform == platform);
	}

	return i.filter(a => a) as apiCredentialsResponseItem[];
}



// history
const useHistory = (page = 0) => {
	return useQuery({
		queryKey: [ 'sync-history', page ],
		queryFn: async () => {
			return z.array(z.object({
				id:						z.string(),
				dateStart:				dateSchema,
				dateEnd:				dateSchema,
				newAccounts:			z.number(),
				createdAccounts:		z.number(),
				updatedAccounts:		z.number(),
				deactivatedAccounts:	z.number(),
				skippedAccounts:		z.number(),
				error:					z.string().nullable(),
			})).parse(await httpRequest(
				'/api/apicredentials/sync-history',
				{
					query: { page: page.toString() },
				}
			));
		},
		enabled: isHistoryVisible,
	});
};
const {
	data:		history,
	isLoading:	isHistoryLoading,
	isError:	isHistoryError,
} = useHistory();


const syncHistoryDetailId		= ref<string | null>(null);
const syncHistoryDetailVisible	= ref(false);
const {
	data:		syncHistoryDetail,
	isLoading:	syncHistoryDetailLoading,
	isError:	syncHistoryDetailError,
} = useQuery({
	queryKey: [ 'sync-history-detail', syncHistoryDetailId ],
	queryFn: async () => {
		return z.object({
			id:			z.string(),
			dateStart:	dateSchema,
			dateEnd:	dateSchema,
			stats:		z.object({
				created:	z.number(),
				instances:	z.record(z.string(), z.object({
					created:		z.array(z.string()),
					updated:		z.array(z.string()),
					deactivated:	z.array(z.string()),
					skipped:		z.array(z.string()),
				})),
			}),
			error:		z.optional(z.string().nullable()),
		}).parse(await httpRequest(`/api/apicredentials/sync-history/${syncHistoryDetailId.value}`));
	},
	enabled: syncHistoryDetailVisible,
});



// blacklist tab


let blacklistSubmitLabelTimeout: NodeJS.Timeout;
const blacklistSubmitLabel		= ref('Speichern');
const isBlacklistPending		= ref(false);
const isBlacklistDirty			= ref(false);

function countEmails(list: string) {
	return list.split(',')
		.map(email => email.trim())
		.filter(email => z.string().email().safeParse(email).success)
		.length;
}

const {
	isLoading:	isBlacklistLoading,
	isError:	isBlacklistError,
	data:		blacklistEmails,
	refetch:	refetchBlacklistEmails,
} = useQuery({
	queryKey: [ 'blacklist' ],
	queryFn: async () => {
		return z.array(z.object({ email: z.string() }))
			.parse(await httpRequest('/api/apicredentials/blacklist'));
	},
	enabled: false,
});

const currentBlacklist = ref('');
watch(blacklistEmails, v => {
	currentBlacklist.value = v.map(item => item.email).join(', ');
});


const {
	data:		deactivationWhitelist,
	isLoading:	isLoadingDeactivationWhitelist,
	refetch:	refetchDeactivationWhitelist,
} = useQuery({
	queryKey: [ 'deactivation-whitelist' ],
	queryFn: async () => {
		return z.array(z.object({ email: z.string() }))
			.parse(await httpRequest('/api/apicredentials/deactivation-whitelist'));
	},
	enabled: false,
});

const currentDeactivationWhitelist = ref('');
watch(deactivationWhitelist, v => {
	currentDeactivationWhitelist.value = v.map(item => item.email).join(', ');
});


watch(isBlacklistVisible, isBlacklistVisible => {
	if (!isBlacklistVisible) return;

	refetchDeactivationWhitelist();
	refetchBlacklistEmails();
}, { immediate: true });




const showSyncHistoryDetail = (id: string) => {
	syncHistoryDetailId.value		= id;
	syncHistoryDetailVisible.value	= true;
};


const formatDate = (date: string | Date) => {
	if (!date) return 'Nie';
	return new Intl.DateTimeFormat('de-de', {
		hour12:	false,
		hour:	'numeric',
		minute:	'numeric',
		second:	'numeric',
		day:	'numeric',
		month:	'short',
		year:	'numeric',
	}).format(new Date(date));
};


const onBlacklistSubmit			= async () => {
	clearTimeout(blacklistSubmitLabelTimeout);

	isBlacklistPending.value	= true;
	blacklistSubmitLabel.value	= 'Bitte warten...';

	try {
		await httpPostRequest(
			'/api/apicredentials/blacklist',
			currentBlacklist.value.split(',').map(item => item.trim())
		);
		await httpPostRequest(
			'/api/apicredentials/deactivation-whitelist',
			currentDeactivationWhitelist.value.split(',').map(item => item.trim()).filter(a => a)
		);

		isBlacklistDirty.value = false;
		blacklistSubmitLabel.value = 'Gespeichert!';
	}
	catch (err) {
		console.error(err);
		blacklistSubmitLabel.value = 'Fehler beim Speichern';
	}

	blacklistSubmitLabelTimeout = setTimeout(() => {
		blacklistSubmitLabel.value = 'Speichern';
	}, 3000);
	isBlacklistPending.value = false;
};
</script>


<template lang="pug">
.flex
	.flex-1: cfx-title.mb-12 Sync-Status

	.flex-none: .tabs
		.tab.tab-bordered(
			:class="{ 'tab-active': currentTab == 'status'}"
			@click="currentTab = 'status'"
		) Letzter Datenstand
		.tab.tab-bordered(
			:class="{ 'tab-active': currentTab == 'history'}"
			@click="currentTab = 'history'"
		) Sync-Historie
		.tab.tab-bordered(
			:class="{ 'tab-active': currentTab == 'blacklist'}"
			@click="currentTab = 'blacklist'"
		) E-Mail Blacklist

template(v-if="isStatusVisible")
	cfx-title(
		v-if="isStatusLoading"
		class="text-center p-12 text-slate-200 dark:text-slate-700"
	) Loading...
	cfx-title(
		v-if="isStatusError"
		class="text-center p-12 text-slate-200 dark:text-slate-700"
	) Fehler beim Laden der Daten


	table.table.table-compact.table-zebra.w-full.border-separate.border-spacing-0(v-if="accounts?.length")
		tr(class=`
			z-10 sticky top-0 bg-white dark:bg-slate-900
			backdrop-blur [@supports(backdrop-filter:blur(0px))]:bg-white/75 [@supports(backdrop-filter:blur(0px))]:dark:bg-slate-900/90
		`)
			//- th(class="border-b border-slate-500/25"): label: input.checkbox(type="checkbox")
			th(class="border-b border-slate-500/25") E-Mail
			th(class="border-b border-slate-500/25") Quellen
			th(class="border-b border-slate-500/25") Ziele

		tr(v-for="account in accounts")
			//- td(class="border-b border-slate-500/25"): label: input.checkbox(type="checkbox")
			td(class="border-b border-slate-500/25") {{ account.email }}
			td(class="border-b border-slate-500/25"): .flex.flex-wrap
				.dropdown.dropdown-top.dropdown-hover.mr-2(
					v-if="getInstancesByPlatform(account, 'planday').length > 1"
				)
					exclamation-icon(class="h-5 w-5 hover:text-red-400")
					div(class=`
							dropdown-content whitespace-normal max-w-md
							card shadow
							backdrop-blur [@supports(backdrop-filter:blur(0px))]:bg-white/80 [@supports(backdrop-filter:blur(0px))]:dark:bg-slate-800/90
							-translate-y-2 -translate-x-8
						`
						tabindex="0" style="width:400px"
					): .card-body: p Dieser Account existiert auf mehreren Quellen. Es werden die Daten der ersten Quelle verwendet ({{ getInstancesByPlatform(account, 'planday')[0].name }})

				.badge.mr-2.badge-primary(
					v-for="instance, index in getInstancesByPlatform(account, 'planday')"
					:class="{'badge-outline': index > 0}"
				) {{ instance.name }}
			td(class="border-b border-slate-500/25"): .badge.badge-primary.mr-2(
				v-for="instance in getInstancesByPlatform(account, 'eloomi')"
			) {{ instance.name }}

template(v-if="isHistoryVisible")
	cfx-title(
		v-if="isHistoryLoading"
		class="text-center p-12 text-slate-200 dark:text-slate-700"
	) Loading...
	cfx-title(
		v-if="isHistoryError"
		class="text-center p-12 text-slate-200 dark:text-slate-700"
	) Fehler beim Laden der Daten


	table.table.table-compact.table-zebra.w-full.border-separate.border-spacing-0(v-if="history?.length")
		tr(class=`
			z-10 sticky top-0 bg-white dark:bg-slate-900
			backdrop-blur [@supports(backdrop-filter:blur(0px))]:bg-white/75 [@supports(backdrop-filter:blur(0px))]:dark:bg-slate-900/90
		`)
			th(class="border-b border-slate-500/25") &nbsp;
			th(class="border-b border-slate-500/25") Zeitpunkt
			th(class="border-b border-slate-500/25") Laufzeit
			th(class="border-b border-slate-500/25") Neue Accounts
			th(class="border-b border-slate-500/25") Accounts erstellt
			th(class="border-b border-slate-500/25") Accounts aktualisiert
			th(class="border-b border-slate-500/25") Accounts deaktiviert
			th(class="border-b border-slate-500/25") Accounts übersprungen

		tr(
			v-for="result in history"
			class=`
				cursor-pointer
				[&:hover>td]:bg-slate-100 dark:[&:hover>td]:bg-slate-800
			`
			@click="showSyncHistoryDetail(result.id)"
		)
			td(class="border-b border-slate-500/25")
				div(:class="`indicator indicator-center indicator-middle w-2 h-2 rounded-full ${result.error ? 'bg-red-600' : 'bg-green-600'}`")
			td(class="border-b border-slate-500/25") {{ formatDate(result.dateEnd) }}
			td(class="border-b border-slate-500/25") {{ ((result.dateEnd.getTime() - result.dateStart.getTime()) / 1000).toFixed(2) }}s
			td(class="border-b border-slate-500/25") {{ result.newAccounts }}
			td(class="border-b border-slate-500/25") {{ result.createdAccounts }}
			td(class="border-b border-slate-500/25") {{ result.updatedAccounts }}
			td(class="border-b border-slate-500/25") {{ result.deactivatedAccounts }}
			td(class="border-b border-slate-500/25") {{ result.skippedAccounts }}

form(v-if="isBlacklistVisible" @submit.prevent="onBlacklistSubmit")
	cfx-title(
		v-if="isBlacklistLoading"
		class="text-center p-12 text-slate-200 dark:text-slate-700"
	) Loading...
	cfx-title(
		v-if="isBlacklistError"
		class="text-center p-12 text-slate-200 dark:text-slate-700"
	) Fehler beim Laden der Daten

	p
		| Alle hier eingetragenen E-Mail Adressen werden bei der Synchronisation
		| übersprungen. (kommasepariert, Groß- und Kleinschreibung-unabhängig)

	p.text-sm.mt-4 Anzahl der E-Mails in dieser Blacklist: {{ countEmails(currentBlacklist) }}
	textarea(
		class=`
			textarea mt-2 font-mono rounded w-full
			bg-slate-200 dark:bg-slate-800 hover:bg-slate-100 dark:hover:bg-slate-700
			hover:text-primary-600 dark:hover:text-primary-50
			focus-visible:outline focus-visible:outline-primary-400
			focus-visible:bg-slate-100 dark:focus-visible:bg-slate-700
			focus-visible:text-primary-600 dark:focus-visible:text-primary-50
		`
		v-model="currentBlacklist"
		@input="isBlacklistDirty = true"
	)

	h2.text-2xl.font-bold.mb-6.mt-12 Von Deaktivierung ausschließen
	p
		| Bei der Synchronisation werden alle Accounts auf der Zielinstanz
		| deaktivert, die auf allen Quellen ebenfalls deaktiviert sind. Alle
		| hier eingetragenen E-Mail Adressen werden von dieser Logik
		| ausgeschlossen

	p.text-sm.mt-4 Anzahl der E-Mails in dieser Liste: {{ countEmails(currentDeactivationWhitelist) }}
	textarea(
		class=`
			textarea mt-2 font-mono rounded w-full
			bg-slate-200 dark:bg-slate-800 hover:bg-slate-100 dark:hover:bg-slate-700
			hover:text-primary-600 dark:hover:text-primary-50
			focus-visible:outline focus-visible:outline-primary-400
			focus-visible:bg-slate-100 dark:focus-visible:bg-slate-700
			focus-visible:text-primary-600 dark:focus-visible:text-primary-50
		`
		v-model="currentDeactivationWhitelist"
		@input="isBlacklistDirty = true"
	)

	.text-center.mt-6: button.btn.btn-primary(
		type="submit"
		:disabled="isBlacklistPending"
	) {{ blacklistSubmitLabel }}


.modal(
	:class="{ 'modal-open': syncHistoryDetailVisible }"
	v-if="syncHistoryDetailVisible && syncHistoryDetail"
	@click="syncHistoryDetailVisible = false"
)
	div(
		class=`
			modal-box relative p-6 rounded-lg
			bg-slate-100 dark:bg-slate-700
		`
		@click.stop=""
	)
		cfx-title Sync Details

		.my-4
			p Status:
				span.ml-2.text-green-600(v-if="!syncHistoryDetail.error") Erfolgreich
				span.ml-2.text-red-600(v-else) Fehlgeschlagen

		table.my-4.table.table-compact
			tr
				td: strong Zeitraum von:
				td {{ formatDate(syncHistoryDetail.dateStart) }}
			tr
				td: strong Zeitraum bis:
				td {{ formatDate(syncHistoryDetail.dateEnd) }}
			tr
				td: strong Laufzeit:
				td {{ ((syncHistoryDetail.dateEnd.getTime() - syncHistoryDetail.dateStart.getTime()) / 1000).toFixed(2) }}s

		table.my-4.table.table-compact
			tr
				td: strong Neue Accounts:
				td {{ syncHistoryDetail.stats.created }}
			tr
				td: strong Accounts angelegt:
				td
					span(v-if="!Object.values(syncHistoryDetail.stats.instances).length") 0
					span(v-else v-for="instance of syncHistoryDetail.stats.instances") {{ instance.created.length }}
			tr
				td: strong Accounts aktualisiert:
				td
					span(v-if="!Object.values(syncHistoryDetail.stats.instances).length") 0
					span(v-else v-for="instance of syncHistoryDetail.stats.instances") {{ instance.updated.length }}
			tr
				td: strong Accounts deaktiviert:
				td
					span(v-if="!Object.values(syncHistoryDetail.stats.instances).length") 0
					span(v-else v-for="instance of syncHistoryDetail.stats.instances") {{ instance.deactivated.length }}
			tr
				td: strong Accounts übersprungen:
				td
					span(v-if="!Object.values(syncHistoryDetail.stats.instances).length") 0
					span(v-else v-for="instance of syncHistoryDetail.stats.instances") {{ instance.skipped.length }}


		.my-4(v-if="syncHistoryDetail.error")
			strong Fehlermeldung:
			pre {{ syncHistoryDetail.error }}
</template>
