<script lang="ts" setup>
	import { ref, computed, onMounted } from 'vue';
	import { z } from 'zod';

	import { httpPostRequest, httpRequest } from '../http-request';

	import CfxTitle from '../components/title.vue';
	import CfxSelect from '../components/select.vue';
	import CfxButton from '../components/button.vue';

	const departmentParser = z.object({
		id:					z.string(),
		name:				z.string(),
		mappingTarget:		z.optional(z.string().nullable()),
		mappingInstance:	z.optional(z.string().nullable()),
	});
	type department = z.infer<typeof departmentParser>;
	const instanceParser = z.object({
		id:					z.string(),
		name:				z.string(),
		platform:			z.string(),
		departments:		z.array(departmentParser),
	});
	type instance = z.infer<typeof instanceParser>;
	type departmentMapping = {
		from:				string;
		instanceFromId:		string;
		to:					string;
		instanceToId:		string;
	};


	const isLoading = ref(false);
	const isError	= ref(false);
	const instances	= ref({
		eloomi:		[] as instance[],
		planday:	[] as instance[],
	});
	const updatedDepartments = ref({} as Record<string, departmentMapping>);
	const fetchInstances = async () => {
		isLoading.value = true;
		try {
			const fetchedInstances	= {
				eloomi:		[] as instance[],
				planday:	[] as instance[],
			};

			const response	= z.array(instanceParser)
				.parse(await httpRequest('/api/departments'));

			for (const instance of response) {
				if (instance.platform == 'eloomi') {
					fetchedInstances.eloomi.push(instance);
				}
				if (instance.platform == 'planday') {
					fetchedInstances.planday.push(instance);
				}
			}

			instances.value				= fetchedInstances;
			updatedDepartments.value	= {};
			isError.value				= false;
		}
		catch (err) {
			isError.value = true;
		}
		isLoading.value = false;
	};


	onMounted(() => { fetchInstances() });


	const optionsMappingTarget = computed(() => {
		const options = { '': 'Nicht synchronisieren' };

		if (instances.value) {
			for (const instance of instances.value.eloomi) {
				for (const department of instance.departments) {
					options[department.id] = department.name;
				}
			}
		}

		return options;
	});


	function setMappingTarget(
		fromInstance:	string,
		fromDepartment:	string,
		toInstance:		string,
		toDepartment:	string,
	) {
		const department = instances.value
			.planday.find(instance => instance.id == fromInstance)
			.departments.find(dep => dep.id == fromDepartment);

		department.mappingInstance	= toInstance;
		department.mappingTarget	= toDepartment;

		console.log(`${fromInstance}_${fromDepartment}`);
		updatedDepartments.value[`${fromInstance}_${fromDepartment}`] = {
			instanceFromId:	fromInstance,
			from:			fromDepartment,
			instanceToId:	toInstance,
			to:				toDepartment,
		};
	}

	let toSave;
	const isSaving		= ref(false);
	const lblSaveButton	= ref('Speichern');
	async function onSubmit() {
		if (isSaving.value) return;
		isSaving.value = true;
		lblSaveButton.value = 'Wird gespeichert...';

		try {
			await httpPostRequest('/api/departments/mappings', Object.values(updatedDepartments.value));
			updatedDepartments.value = {};
			lblSaveButton.value = 'Gespeichert!';

			clearTimeout(toSave);
			toSave = setTimeout(() => lblSaveButton.value = 'Speichern', 3000);
		}
		catch(err) {
			alert('Daten konnten nicht gespeichert werden');
		}

		isSaving.value = false;
	}
</script>


<template lang="pug">
cfx-title Department Mapping

cfx-title(class="text-center p-12" v-if="isLoading") Loading...
cfx-title(class="text-center p-12" v-if="isError") Departments konnten nicht geladen werden

form(class="my-6" v-if="instances.planday.length" @submit.prevent="onSubmit")
	section(
		v-for="instance of instances.planday"
		class="card bg-slate-500/10 px-6 py-6 mt-4"
	)
		cfx-title.mb-8 {{ instance.name }}

		ul
			li(
				v-for="department in instance.departments"
				:key="department.id"
				class="border-b dark:border-slate-800 px-6 py-3 last:border-b-0"
			)
				.flex.items-center
					.flex-none {{ department.name }}
					.flex-1
					.flex-none: cfx-select(
						:options="optionsMappingTarget"
						:model-value="department.mappingTarget"
						@update:model-value="setMappingTarget(instance.id, department.id, instances.eloomi[0].id, $event)"
					)

	.text-center.mt-6
		cfx-button(type="submit" :disabled="isSaving") {{ lblSaveButton }}
</template>
