import { users } from '@prisma/client';
import { ref } from 'vue';
import { useQuery } from "@tanstack/vue-query";
import { httpDeleteRequest, httpPostRequest, httpRequest } from './http-request';


export type LoginStatus = null | boolean | users;
export const user = ref<LoginStatus>();

export const useUser = (userId: string, argsQuery: any = {}) =>
	useQuery({
		queryKey: [ 'user', userId ],
		queryFn: () => httpRequest(`/api/users/details/${userId}`),
		...argsQuery,
	});

export const useUsers = (filter: any = {}, argsQuery?: any) =>
	useQuery({
		queryKey: [ 'users', filter ],
		queryFn: () => httpRequest('/api/users', filter),
		...argsQuery,
	});


export async function getUser(id?: string) {
	if (user.value) return user.value;

	let url = '/api/users/details';
	if (id) url += `/${id}`;

	await httpRequest(url)
		.then(v => user.value = v)
		.catch(err => user.value = false);

	return user.value;
}

export const registerUser = /* async */ (
	email: string,
	password: string,
) => httpPostRequest('/api/users/create', { email, password });

export const deleteUser = /* async */ (
	id: string,
) => httpDeleteRequest(`/api/users/delete/${id}`);

export const login = async (
	email: string,
	password: string
) => {
	try {
		user.value = await httpPostRequest('/api/users/login', { email, password });
	}
	catch (err) {
		user.value = false;
		throw err;
	}
}
export const logout = async () => {
	await httpPostRequest('/api/users/logout');
	user.value = false;
}
