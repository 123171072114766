import {
    createRouter,
    createWebHistory,
} from 'vue-router';

import {
    getUser
} from './user';

import Dashboard from './views/dashboard.vue';
import UserLogin from './views/user/login.vue';
import UserRegister from './views/user/register.vue';
import UserList from './views/user/index.vue';
import UserEdit from './views/user/edit.vue';
import Logs from './views/logs/index.vue';
import Api from './views/api.vue';
import DepartmentMapping from './views/department-mapping.vue';
import SyncActions from './views/sync-actions.vue';
import Cron from './views/cron.vue';
import SyncStatus from './views/status.vue';

import LayoutPresentation from './layouts/presentation.vue';


const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/',
            redirect: '/dashboard',
        },
        {
            name: 'dashboard',
            path: '/dashboard',
            component: Dashboard,
        },
        {
            name: 'login',
            path: '/login',
            component: UserLogin,
            meta: {
                layout: LayoutPresentation,
            },
        },
        {
            name: 'register',
            path: '/user/register',
            component: UserRegister,
        },

        {
            name: 'editUser',
            path: '/user/edit/:userId',
            component: UserEdit,
            props: true,
        },

        {
            path: '/user',
            component: UserList,
        },

        {
            path: '/logs',
            component: Logs,
        },

        {
            path: '/credentials',
            component: Api,
        },
        {
            path: '/sync-status',
            component: SyncStatus,
        },

        {
            path: '/department',
            component: DepartmentMapping,
        },

        {
            path: '/sync-actions',
            component: SyncActions,
        },

        {
            path: '/cron',
            component: Cron,
        },
    ],
});

router.beforeEach(async (to) => {
    const getUserSafe = async () => {
        try {
            return await getUser();
        } catch (err) {}
        return false;
    };

    const user = await getUserSafe();

    if (to.name != 'login' && !user) {
        return {
            name: 'login'
        };
    } else if (to.name == 'login' && user) {
        return {
            name: 'dashboard'
        };
    }
});

export default router;
