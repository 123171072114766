<script lang="ts" setup>
	import { ref, onMounted, computed, watch } from 'vue';
	import { onBeforeRouteUpdate } from 'vue-router';

	import { z } from 'zod';
	import { useQueryClient } from '@tanstack/vue-query';

	import { useSyncActions } from '../queries/sync-actions';
	import { httpPostRequest } from '../http-request';

	import { syncActionParser } from '../parsers/sync-actions';
	import type { syncAction } from '../parsers/sync-actions';

	import CfxTitle from '../components/title.vue';
	import CfxSelect from '../components/select.vue';
	import CfxButton from '../components/button.vue';
	import SyncAction from '../components/sync-action.vue';


	const queryClient = useQueryClient();


	const props = defineProps<{}>();



	const currentRules		= ref<syncAction[]>();
	const additionalActions	= ref<Omit<syncAction, 'id'>[]>([]);
	const deleteActions		= ref<string[]>([]);

	const {
		data:		syncActions,
		isLoading:	isLoadingSyncActions,
		isError:	isErrorSyncActions,
		refetch: 	refetchSyncActions,
	} = useSyncActions({ refetchOnWindowFocus: false });
	onMounted(() => { refetchSyncActions(); });
	onBeforeRouteUpdate(() => { refetchSyncActions(); });

	watch(syncActions, setCurrentRules, { immediate: true });
	function setCurrentRules() {
		currentRules.value = syncActions.value
			? JSON.parse(JSON.stringify(syncActions.value))
			: [];
	}

	const currentSyncActionsNotDeleted = computed(() => {
		return currentRules.value?.filter(rule => {
			return !deleteActions.value.includes(rule.id);
		});
	});


	function onClickCreateAction() {
		additionalActions.value = [
			...additionalActions.value,
			{
				enabled:		false,
				description:	'',
				rules:			[],
				actions:		[],
			},
		];
	}

	function deleteAction(id: string) {
		deleteActions.value = [ ...new Set([ ...deleteActions.value, id ]) ];
	}
	function deleteAdditionalAction(index: number) {
		additionalActions.value.splice(index, 1);
		additionalActions.value = [ ...additionalActions.value ];
	}
	function duplicateAction(action: Omit<syncAction, 'id'> & { id?: string }) {
		const duplicate = JSON.parse(JSON.stringify(action));
		delete duplicate.id;

		additionalActions.value = [
			...additionalActions.value,
			duplicate,
		];
	}

	async function onSubmit() {
		const payload = {
			'update':	[],
			'add':		additionalActions.value,
			'delete':	deleteActions.value,
		};

		for (const rule of syncActions.value) {
			const currentRule = currentRules.value.find(currentRule => currentRule.id == rule.id );
			if (!currentRule) throw new Error('zugehörige regel nicht gefunden');

			if (JSON.stringify(rule) != JSON.stringify(currentRule)) {
				payload.update.push(currentRule);
			}
		}

		const response = z.array(syncActionParser)
			.parse(await httpPostRequest('/api/sync-actions', payload));

		queryClient.setQueryData([ 'sync-actions' ], response);

		additionalActions.value = [];
	}
</script>


<template lang="pug">
form(@submit.prevent="onSubmit")
	cfx-title Sync Aktionen

	cfx-title.text-center.p-12(v-if="isLoadingSyncActions") Loading...
	cfx-title.text-center.p-12(v-else-if="isErrorSyncActions") Aktionen konnten nicht geladen werden
	template(v-else)
		ul.mt-12.flex.flex-col.gap-6
			li.rounded-lg.p-6(
				v-for="rule, i in currentSyncActionsNotDeleted"
				:key="currentSyncActionsNotDeleted[i].id"
				class="bg-slate-100 dark:bg-slate-800"
			)
				sync-action(
					v-model="currentSyncActionsNotDeleted[i]"
					@delete="deleteAction(currentSyncActionsNotDeleted[i].id)"
					@duplicate="duplicateAction(rule)"
				)
			li.rounded-lg.p-6(
				v-for="rule, i in additionalActions"
				class="bg-slate-100 dark:bg-slate-800"
			)
				sync-action(
					v-model="additionalActions[i]"
					@delete="deleteAdditionalAction(i)"
					@duplicate="duplicateAction(rule)"
				)

		.text-center.mt-12
			cfx-button(@click="onClickCreateAction") Neues Set anlegen

		.text-center.mt-12
			cfx-button(type="submit") Speichern
</template>
