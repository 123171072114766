<script setup lang="ts">
	export interface IProps {
		modelValue:	any;
		options:	Record<string, unknown>;
	};

	withDefaults(defineProps<IProps>(), {
		modelValue: '',
		options:	() => ({}),
	});

	const emit = defineEmits([
		'update:modelValue',
	]);

	const onInput = (e: Event) => {
		emit('update:modelValue', (e.target as HTMLInputElement).value);
	};
</script>

<template lang="pug">
select(
	class=`
		py-2 px-4 min-w-button rounded-lg

		bg-slate-200 dark:bg-slate-800

		hover:bg-slate-100 dark:hover:bg-slate-700
		hover:text-primary-600 dark:hover:text-primary-50

		focus-visible:outline focus-visible:outline-primary-400
		focus-visible:bg-slate-100 dark:focus-visible:bg-slate-700
		focus-visible:text-primary-600 dark:focus-visible:text-primary-50

		placeholder:italic placeholder:dark:text-slate-200/25
	`
	:value="modelValue"
	@input="onInput"
)
	option(
		v-for="value, key in options"
		:key="key"
		:value="key"
	) {{ value }}
</template>
