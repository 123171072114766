<script setup lang="ts">
	import { ref } from 'vue';
	import { useQuery } from 'vue-query';

	import CfxTitle from '../../components/title.vue';
	import CfxButton from '../../components/button.vue';
import { httpPostRequest, httpRequest } from '../../http-request';

	const isInfosLoading	= ref(false);

	interface ILog {
		type:		string;
		timestamp:	Date;
		message:	string;
	}


	function formatTimestamp(timestamp: Date) {
		return timestamp.toLocaleString('de-de', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});
	}


	const {
		data: logs,
		refetch,
	} = useQuery([ 'logs' ], async (): Promise<ILog[]> => {
		const response = await httpRequest(`/api/logs`);
		return response.map((log: ILog) => {
			log.timestamp = new Date(log.timestamp);
			return log;
		});
	});

	const clearLogs = async () => {
		const response = await httpPostRequest('/api/logs/clear');
		refetch.value();
	};
</script>

<template lang="pug">
.flex.items-end
	.flex-1: cfx-title Logs
	.flex-none: cfx-button.cfx-button-small.cfx-button-danger(@click="clearLogs") Logs leeren

cfx-title(class="text-center p-12" v-if="isInfosLoading") Loading...

div(class="my-6" v-if="!isInfosLoading")
	pre.w-full.overflow-scroll(v-if="logs && logs.length"): template(v-for="log in logs")
		| {{ formatTimestamp(log.timestamp) }} | {{ log.type }}: {{ log.message }} {{ "\n" }}
	div.font-bold.text-2xl.text-center.p-12(v-else) Keine Logs
</template>
