<script setup lang="ts">
	import { ref } from 'vue';

	const props = defineProps({
		type:	{ type: String, default: 'button' },
		href:	{ type: String, default: null },
		to:		{ type: null,	default: null },
	});

	const tag = ref(
		props.to ?		'router-link' :
		props.href ?	'a' :
			'button'
	);
</script>


<template lang="pug">
component.cfx-button.text-center(
	:is="tag"
	v-bind="{ type, href, to }"
): slot
</template>
