<script setup lang="ts">
	import { onBeforeRouteUpdate, useRouter } from 'vue-router';

	import CfxTitle from '../../components/title.vue';
	import CfxButton from '../../components/button.vue';

	import FormEdit from './form-edit.vue';

	import { ref, watch } from 'vue';
	import { useUser } from '../../user';
	import { httpPostRequest } from '../../http-request';
	import { users } from '@prisma/client';


	const props = defineProps({
		userId:	{ type: String, required: true },
	});

	const router = useRouter();

	const {
		isLoading,
		isError,
		refetch,
		data: userData,
	} = useUser(props.userId, { enabled: false });


	const user = ref<users>();
	watch(() => userData.value, data => user.value = data);

	refetch.value();
	onBeforeRouteUpdate(() => {
		refetch.value();
	});


	async function submit() {
		try {
			await httpPostRequest(
				`/api/users/update/${props.userId}`,
				user.value
			);
			router.push('/user');
		}
		catch (err) {
			console.error(err);
		}
	}
</script>

<template lang="pug">
form(class="px-8 py-6" @submit.prevent="submit")
	cfx-title(class="sm:col-span-2 mb-6") Benutzer bearbeiten

	div(v-if="user")
		form-edit(v-model="user")
		.text-center.mt-6
			cfx-button(type="submit") Speichern

	cfx-title(class="text-center p-12 text-slate-200 dark:text-slate-700" v-if="isLoading") Loading...
	cfx-title(class="text-center p-12 text-slate-200 dark:text-slate-700" v-if="isError") Benutzer konnte nicht geladen werden
</template>
