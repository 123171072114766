<script setup lang="ts">
	import { computed } from 'vue';
	import { useRouter } from 'vue-router';

	import CfxHeader from "../components/header.vue";
	import CfxButton from '../components/button.vue';

	import { ExclamationIcon } from '@heroicons/vue/outline';

	import { user, logout } from '../user';
	import type { authInfo } from '../../../routes/users';


	const router = useRouter();

	const userInfo			= computed(() => user.value as authInfo);
	const isAuthBypassed	= computed(() => userInfo.value?.isBypassed);


	async function requestLogout() {
		if (!window.confirm('Möchten Sie sich wirklich ausloggen?')) return;

		try {
			await logout();
			router.push({ name: 'login' });
		}
		catch (err) {
			console.error(err);
		}
	}
</script>

<template lang="pug">

div(class=`flex-none text-center bg-slate-900 border-b border-slate-500/25`): cfx-header.p-4
.flex-1.flex.max-w-full.mx-auto.w-content.overflow-hidden
	div(class="overflow-y-scroll flex-none w-aside border-r border-slate-500/25")
		.min-h-full.p-6.flex.flex-col
			.flex-1: ul
				li.block: router-link.router-link(:to="{ name: 'dashboard' }") Dashboard
				li.block: router-link.router-link(to="/credentials") API Zugänge
				li.block: router-link.router-link(to="/department") Department Mapping
				li.block: router-link.router-link(to="/sync-actions") Sync Aktionen
				li.block: router-link.router-link(to="/sync-status") Sync Status
				li.block: router-link.router-link(to="/cron") Automatisierte Prozesse
				li.block: router-link.router-link(to="/user") System-Benutzer
				li.block: router-link.router-link(to="/logs") Logs

			.flex-none.mt-6
				template(v-if="isAuthBypassed")
					.flex.justify-center.items-center.text-red-600
						.flex-none.mr-4: exclamation-icon.w-5.h-5
						.flex-none AUTH BYPASS MODE

				template(v-else)
					.text-center.p-1.text-slate-400(v-if="userInfo"): small {{ userInfo.email }}
					cfx-button.w-full.cfx-button-small.cfx-button-danger(@click="requestLogout") Logout

	.flex-1.overflow-y-scroll
		.min-h-full.py-12.px-6
			slot(name="content")
</template>
