<script setup lang="ts">
    import { users } from '@prisma/client';
    import { computed } from 'vue';

	import CfxInput from '../../components/input.vue';

    const props = defineProps<{
        modelValue:   users;
    }>();

    const emit = defineEmits([ 'update:modelValue' ]);

    const user = computed({
        get:	() => props.modelValue,
        set:	v => emit('update:modelValue', v),
    });
</script>

<template lang="pug">
.mt-4: cfx-input.w-full(type="text" v-model="user.email" placeholder="email")
.mt-4: cfx-input.w-full(type="text" v-model="user.password" placeholder="password")
</template>
