<script setup lang="ts">
	import { ref } from 'vue'
	import { useRouter } from 'vue-router';

	import { users } from '@prisma/client';

	import { registerUser } from '../../user.js';

	import CfxTitle from '../../components/title.vue';
	import CfxButton from '../../components/button.vue';
	import FormEdit from './form-edit.vue';


	defineProps({
		logo:		{ type: String, default: null },
		subline:	{ type: String, default: null },
	});

	const router = useRouter();

	const user = ref<users>({
		email:		'',
		password:	'',
	} as users);

	async function submit() {
		try {
			await registerUser(user.value.email, user.value.password);
			router.push('/user');
		}
		catch (err) {
			console.error(err);
		}
	}
</script>

<template lang="pug">
form(class="px-8 py-6" @submit.prevent="submit")
	cfx-title(class="sm:col-span-2 mb-6") Neuen Benutzer registrieren

	div(v-if="user")
		form-edit(v-model="user")
		.text-center.mt-6
			cfx-button(type="submit") Registrieren
</template>
