import { z } from 'zod';


export const syncActionRuleParser = z.object({
	type:			z.string(),
	target:			z.string(),
	data:			z.any(),
});
export type syncActionRule = z.infer<typeof syncActionRuleParser>;

export const syncActionActionParser = z.object({
	type:			z.string(),
	data:			z.any(),
});
export type syncActionAction = z.infer<typeof syncActionActionParser>;

export const syncActionParser = z.object({
	id:				z.string(),
	enabled:		z.boolean(),
	description:	z.string(),
	rules:			z.array(syncActionRuleParser),
	actions:		z.array(syncActionActionParser),
});
export type syncAction = z.infer<typeof syncActionParser>;
export type syncActionNoId = Omit<syncAction, 'id'>;



export const departmentParser = z.object({
	id:					z.string(),
	name:				z.string(),
	mappingTarget:		z.optional(z.string().nullable()),
	mappingInstance:	z.optional(z.string().nullable()),
});
export type department = z.infer<typeof departmentParser>;
export const instanceParser = z.object({
	id:					z.string(),
	name:				z.string(),
	platform:			z.string(),
	departments:		z.array(departmentParser),
});
export type instance = z.infer<typeof instanceParser>;
