export const domain = import.meta.env.VITE_URL_SERVER || new URL('/', window.location.href).href;
export const httpRequest = async (url: string, args?: RequestInit & { query?: Record<string, string> }) => {

    const requestUrl = new URL(url, domain);
    if (args?.query) {
        for (const q of Object.entries(args.query)) {
            requestUrl.searchParams.set(q[0], q[1]);
        }
    }

    const request = await fetch(requestUrl.href, {
        credentials: 'include',
        ...args,
    });

    if (!request.ok) {
        throw new Error(`Network Error (Code: ${request.status}) "${request.statusText}"`);
    }

    const responseText = await request.text();
    try {
        return JSON.parse(responseText);
    }
    catch (err) {
        return responseText;
    }
};
export const httpPostRequest = (url: string, body: any = {}, args: RequestInit = {}) => {
    return httpRequest(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        ...args
    });
};
export const httpDeleteRequest = (url: string, body: any = {}, args: RequestInit = {}) => {
    return httpRequest(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        ...args,
    });
};
