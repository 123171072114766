import { createApp } from 'vue'
import './style.scss'
import router from './router.js';
import Main from './main.vue';
import { VueQueryPlugin } from "@tanstack/vue-query";



const app = createApp(Main);
app.use(VueQueryPlugin);
app.use(router);
app.mount('#app')
