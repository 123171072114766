import { z } from 'zod';
import { useQuery } from '@tanstack/vue-query';

import { syncActionParser } from '../parsers/sync-actions';
import { httpRequest } from '../http-request';


export function useSyncActions(args = {}) {
	return useQuery({
		queryKey: [ 'sync-actions' ],
		queryFn: async () => z.array(syncActionParser)
			.parse(await httpRequest('/api/sync-actions')),
		...args,
	});
}
