<script setup lang="ts">
	import { ref } from 'vue'
	import { useRouter } from 'vue-router';

	import { login } from '../../user';

	import CfxButton from '../../components/button.vue';
	import CfxInput from '../../components/input.vue';
	import CfxHeader from '../../components/header.vue';


	defineProps({
		logo:		{ type: String, default: '/src/assets/benefit-logo.png.webp' },
		subline:	{ type: String, default: 'Planday / Eloomi Sync Administration' },
	});

	const router = useRouter();

	const email = ref('');
	const password = ref('');

	async function submit() {
		try {
			await login(email.value, password.value);
			router.push({ name: 'dashboard' });
		}
		catch (err) {
			console.error(err);
		}
	}
</script>

<template lang="pug">
div(class="rounded-lg mx-auto bg-slate-300 dark:bg-slate-800/50")
	cfx-header(class="block p-4 bg-slate-600 dark:bg-slate-800 text-slate-300 dark:text-slate-500 rounded-t-lg")

	form(class="box-border w-full max-w-2xl px-8 py-6 grid sm:grid-cols-2 gap-5" @submit.prevent="submit")
		cfx-input(type="text" v-model="email" placeholder="email")
		cfx-input(type="password" v-model="password" placeholder="password")

		cfx-button(type="submit" class="sm:col-span-2") Login
</template>
