<script lang="ts" setup>
	import { onMounted, ref } from 'vue';
	import type { ComponentPublicInstance } from 'vue';

	import { httpPostRequest, httpRequest } from '../http-request';

	import CfxTitle from '../components/title.vue';
	import CfxButton from '../components/button.vue';
	import CfxInput from '../components/input.vue';

	import { PlayIcon, ClockIcon, CogIcon, CheckCircleIcon, ExclamationIcon } from '@heroicons/vue/solid';


	import type { apiResponseCron } from '../../../routes/cron';


	const isLoading 		= ref(false);
	const isError			= ref(false);
	const tasks				= ref<apiResponseCron[]>([]);
	const setupScheduleTask	= ref<apiResponseCron | undefined>();
	const inputSchedule		= ref<ComponentPublicInstance>();
	const currentOffset		= ref<number[] | undefined>();
	const currentSchedule	= ref('');


	onMounted(() => updateTasks());


	async function updateTasks() {
		isLoading.value = true;

		try {
			await fetchTasks();
			isError.value = false;
		}
		catch (err) {
			isError.value = true;
			console.error((err as Error).message);
		}

		isLoading.value = false;
	}

	async function fetchTasks() {
		const fetchedTasks = await httpRequest('/api/cron') as apiResponseCron[];
		fetchedTasks.sort((a, b) => a.name.localeCompare(b.name));
		tasks.value = fetchedTasks.map(task => {
			if (task.lastRun) {
				task.lastRun = new Date(task.lastRun);
			}
			return task;
		});
	}

	async function toggleTask(task: apiResponseCron) {
		try {
			const response = await httpPostRequest(`/api/cron/${task.enabled ? 'stop' : 'start'}/${task.name}`);
			await fetchTasks();
		}
		catch (err) {
			console.error((err as Error).message);
		}
	}

	let toCloseModal;
	const isTaskRunning	= ref(false);
	const isTaskSuccess	= ref(false);
	const isTaskError	= ref(false);
	const runTask = async (task: apiResponseCron) => {
		isTaskRunning.value	= true;
		isTaskSuccess.value	= false;
		isTaskError.value	= false;
		try {
			await httpPostRequest(`/api/cron/run/${task.name}`);
			isTaskSuccess.value	= true;
			toCloseModal = setTimeout(closeTaskModal, 3000);
		} catch (err) {
			isTaskError.value	= true;
		}
		isTaskRunning.value = false;
		await updateTasks();
	}
	const closeTaskModal = () => {
		toCloseModal = undefined;
		if (isTaskRunning.value) return;
		isTaskSuccess.value = false;
		isTaskError.value = false;
	};


	function formatTimestamp(timestamp: Date) {
		return timestamp.toLocaleString('de-de', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});
	}

	function showScheduleSetup(task: apiResponseCron) {
		setupScheduleTask.value = task;
		currentSchedule.value = task.schedule || '* * * * * *';
	}

	function updateIndicator() {
		const selStart = inputSchedule.value?.$el.selectionStart;
		const selEnd = inputSchedule.value?.$el.selectionEnd;

		let strStart	= inputSchedule.value?.$el.value;
		let strEnd		= inputSchedule.value?.$el.value;

		const offset = [] as number[];

		const start = strStart
			.slice(Math.max(selStart - 1, 0))
			.split(/\s+/)
			.filter((a: string) => a)
			.length;
		offset.push(start);

		if (selEnd != selStart) {
			const end = strEnd
				.slice(Math.max(selEnd - 2, 0))
				.split(/\s+/)
				.filter((a: string) => a)
				.length;

			if (end != start) {
				for (let i = end; i < start; i++) {
					offset.push(i);
				}
			}
		}

		currentOffset.value = offset;
	}

	async function submitSchedule() {
		if (!(setupScheduleTask.value && currentSchedule.value)) return;
		await httpPostRequest(`/api/cron/update/${setupScheduleTask.value.name}`, {
			schedule: currentSchedule.value,
		});

		setupScheduleTask.value	= undefined;
		currentSchedule.value	= '';

		await updateTasks();
	}
</script>


<template lang="pug">
cfx-title Automatisierte Prozesse

cfx-title(class="text-center p-12" v-if="isLoading") Loading...

div(class="my-6" v-if="!isLoading")
	ul
		li(
			v-for="task in tasks"
			:key="task.name"
			class="border-b dark:border-slate-800 px-6 py-3 last:border-b-0"
		)
			.flex.items-center
				.flex-1
					div {{ task.label || task.name }}
					small(class="block text-slate-400 dark:text-slate-700") Zuletzt ausgeführt: {{ task.lastRun ? formatTimestamp(task.lastRun) : 'Nie' }}
					small(class="block text-slate-400 dark:text-slate-700") {{ task.description }}

				.flex-none.mr-6.tooltip(data-tip="Jetzt auslösen")
					cfx-button(
						class="cfx-button-icon cfx-button-small cfx-button-rounded"
						@click.prevent="runTask(task)"
					): play-icon.h-5.w-5
				.flex-none.mr-6.tooltip(data-tip="Zeitplan einstellen")
					cfx-button(
						class="cfx-button-icon cfx-button-small cfx-button-rounded"
						@click.prevent="showScheduleSetup(task)"
					): clock-icon.h-5.w-5
				.flex-none.tooltip(:data-tip="task.enabled ? 'Deaktivieren' : 'Aktivieren'")
					input.toggle.toggle-primary.block(
						type="checkbox"
						:checked="task.enabled"
						@click="toggleTask(task)"
					)


.modal(
	:class="{ 'modal-open': setupScheduleTask }"
	v-if="setupScheduleTask"
	@click="setupScheduleTask = undefined"
)
	form(
		class=`
			modal-box relative p-6 rounded-lg
			bg-slate-100 dark:bg-slate-700
		`
		@click.stop=""
		@submit.prevent="submitSchedule"
	)
		cfx-title Zeitplan einstellen
		small(class="block text-slate-400 dark:text-slate-500") {{ setupScheduleTask.label || setupScheduleTask.name }}

		p.mt-8.text-sm
			| Der Zeitplan wird mittels GNU Cron Schedule Expression definiert.
			| Falls das zu kompliziert sein sollte, können zahlreiche kostenlose
			| online verfügbare Werkzeuge verwendet werden, um den gewünschten
			| Ausdruck zu erziehen
			| (#[a(href="https://www.bennetrichter.de/tools/crontab-generator/" target="_blank") Beispiel])

		.flex.justify-between.mt-8.text-xs
			span(:class="{ 'underline text-primary-500 dark:text-primary-400': currentOffset?.includes(6) }") Sekunde(optional)
			span(:class="{ 'underline text-primary-500 dark:text-primary-400': currentOffset?.includes(5) }") Minute
			span(:class="{ 'underline text-primary-500 dark:text-primary-400': currentOffset?.includes(4) }") Stunde
			span(:class="{ 'underline text-primary-500 dark:text-primary-400': currentOffset?.includes(3) }") Tag
			span(:class="{ 'underline text-primary-500 dark:text-primary-400': currentOffset?.includes(2) }") Monat
			span(:class="{ 'underline text-primary-500 dark:text-primary-400': currentOffset?.includes(1) }") Wochentag

		.mt-2
			cfx-input.w-full(
				ref="inputSchedule"
				v-model="currentSchedule"
				@focus="updateIndicator"
				@blur="currentOffset = undefined"
				@keyup="updateIndicator"
				@mouseup="updateIndicator"
			)

		.mt-8.text-right
			cfx-button(type="submit") Speichern
			cfx-button.ml-4(@click="setupScheduleTask = undefined") Abbrechen

.modal.modal-open(v-if="isTaskRunning || isTaskSuccess || isTaskError" @click="closeTaskModal")
	div(:class="`modal-box relative p-6 rounded-lg ${!isTaskSuccess && !isTaskError ? 'bg-slate-100 dark:bg-slate-700' : ''} ${isTaskSuccess && !isTaskError ? 'bg-green-200 dark:bg-green-900' : ''} ${!isTaskSuccess && isTaskError ? 'bg-red-100 dark:bg-red-900' : ''}`")
		.flex.justify-center.items-center.text-green-600(v-if="isTaskSuccess")
			.flex-none: check-circle-icon.w-6.h-6
			.flex-none: span.ml-4.text-lg Task erfolgreich ausgeführt!
		.flex.justify-center.items-center.text-red-500(v-else-if="isTaskError")
			.flex-none: exclamation-icon.w-6.h-6
			.flex-none: span.ml-4.text-lg Task ist fehlgeschlagen!
		.flex.justify-center.items-center(v-else)
			.flex-none: cog-icon.w-6.h-6.animate-spin(style="animation-duration: 2s;")
			.flex-none: span.ml-4.text-lg Task wird ausgeführt...

</template>
