<script setup lang="ts">
	import { shallowRef, watch } from 'vue';
	import { useRouter } from 'vue-router';
	import DefaultLayout from './layouts/default.vue';

	const router = useRouter();

	const layout = shallowRef(DefaultLayout);

	watch(() => router.currentRoute.value, (route) => {
		layout.value = route.meta.layout || DefaultLayout;
	});
</script>

<template lang="pug">
component(:is="layout"): template(#content): router-view
</template>
