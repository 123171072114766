<script setup lang="ts">
	import { computed, ref } from 'vue';

	import { user, useUsers, deleteUser } from '../../user';
	import type { users as dbUser } from '@prisma/client';

	import CfxTitle from '../../components/title.vue';
	import CfxButton from '../../components/button.vue';


	const {
		isLoading,
		data:		users,
		refetch:	refetchUsers,
	} = useUsers();

	const currentUser = computed(() => user.value as dbUser);
	const queriedUserDeletionId	= ref();


	async function onDeleteClicked() {
		deleteUser(queriedUserDeletionId.value).then(() => {
			return refetchUsers.value();
		});
		queriedUserDeletionId.value = null;
	}
</script>

<template lang="pug">
cfx-title Benutzer

cfx-title(class="text-center p-12" v-if="isLoading") Loading...

div(class="my-6" v-if="!isLoading")
	ul
		li(
			v-for="user in users"
			:key="user.email"
			class="border-b dark:border-slate-800 px-6 py-3 last:border-b-0"
		)
			.flex.items-center
				.flex-1 {{ user.email }}
				.flex-none.ml-4: cfx-button.cfx-button-small(
					:to="{ name: 'editUser', params: { userId: user.id } }"
				) Bearbeiten
				.flex-none.ml-4: cfx-button.cfx-button-small.cfx-button-danger(
					:disabled="user.id == currentUser?.id"
					@click="queriedUserDeletionId = user.id"
				) Löschen

	div(class="text-center mt-4")
		cfx-button(to="/user/register") Neuen Benutzer anlegen

.modal(
	:class="{ 'modal-open': queriedUserDeletionId }"
	@click="queriedUserDeletionId = null"
)
	div(
		class=`
			modal-box relative p-6 rounded-lg
			bg-slate-100 dark:bg-slate-700
		`
		@click.stop=""
	)
		cfx-title Benutzer löschen

		p.my-6 Dieser Benutzer wird unwiederuflich gelöscht. Fortfahren?

		.text-right
			cfx-button.cfx-button-danger(
				@click="onDeleteClicked"
			) Löschen
			cfx-button.ml-4(
				@click="queriedUserDeletionId = null"
			) Abbrechen
</template>
