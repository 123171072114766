<script setup lang="ts">
	import { computed } from 'vue';

	import { useUsers } from '../user';
	import { useCredentials } from '../credentials';
	import { httpRequest } from '../http-request';
	import { useQuery } from "@tanstack/vue-query";

	import CfxTitle from '../components/title.vue';


	const {
		isLoading: isUsersLoading,
		data: users,
	} = useUsers();
	const {
		isLoading: isCredentialsLoading,
		data: credentials,
	} = useCredentials();
	const {
		isLoading: isStatsLoading,
		data: stats,
	} = useQuery({
		queryKey: [ 'stats' ],
		queryFn: () => httpRequest('/api/stats'),
	});

	const isLoading = computed(() => {
		return isUsersLoading.value ||
			isCredentialsLoading.value;
	});
	const credentialsInfo = computed(() => {
		if (!credentials.value) return 'Loading...';

		function getPlatformCount(platform: string) {
			return credentials.value
				.filter(c => c.platform == platform)
				.length;
		}

		return `
			Planday: ${getPlatformCount('planday')}<br>
			Eloomi: ${getPlatformCount('eloomi')}
		`;
	});

	const formatDate = (date: string | Date) => {
		if (!date) return 'Nie';
		return new Intl.DateTimeFormat('de-de', {
			hour12: false,
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
		}).format(new Date(date));
	};
</script>


<template lang="pug">
cfx-title Dashboard

cfx-title(class="text-center p-12" v-if="isLoading") Loading...


.my-6(v-if="stats"): .stats.shadow
	//- .stat
	//- 	.stat-title Benutzer
	//- 	.stat-value.text-primary-300(v-html="users?.length.toString()")
	//- .stat
	//- 	.stat-title Schnittstellen
	//- 	.stat-value(v-html="credentialsInfo")
	.stat
		.stat-title Automatische Synchonisation
		.stat-value.text-green-600(v-if="stats.taskActive") Aktiv
		.stat-value.text-slate-600(v-else) Inaktiv
	.stat
		.stat-title zuletzt synchronisiert am
		.stat-value {{ stats ? formatDate(stats.lastSync) : '...' }}

.my-6(v-if="stats"): .stats.shadow
	.stat
		.stat-title Accounts
		.stat-value.text-primary-300 {{ stats.accountsCount }}

</template>
